import { LoginType } from "@/types/user/login-type";
import { ForgotPassType } from "@/types/user/forgot-pwd-type";
import { RegisterType } from "@/types/user/register-type";
import { request } from "@/requests/api-request";
import { AxiosError } from "axios";
import store from "../../store";
import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { getHeaderAuth } from "../handlers-helpers";

export async function loginHandler(payload: LoginType): Promise<unknown | void> {
  return await request(undefined, "POST", "login", undefined, payload, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        store.commit("accountLogin", response);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 403:
          showAlert("To nebylo správně...", "Zadal jsi nesprávné údaje. Prosím zkontroluj si email a heslo", AlertSeverities.warn);
          break;
      }
    });
}

export async function registerHandler(payload: RegisterType): Promise<unknown | void> {
  return await request(undefined, "POST", "register", undefined, payload, undefined)
    .then((response) => {
      showAlert("Úspěch!", "Můžeš se teď přihlásit!", AlertSeverities.success);
      return response;
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 409:
          showAlert("Uh-oh", "Tento účet už existuje.", AlertSeverities.fatal);
          break;
      }
    });
}

export async function forgotPassHandler(payload: ForgotPassType): Promise<unknown | void> {
  return await request(undefined, "POST", "iforgor💀", undefined, payload, undefined)
    .then((response) => {
      showAlert("Úspěch! 💀", "Tvoje přihlašovací údaje byly poslány na tvůj email!", AlertSeverities.success);
      return response;
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 406:
          showAlert("Uh-oh", "Účet s tímto emailem neexistuje.", AlertSeverities.warn, getHeaderAuth());
          break;
      }
    });
}
