
import { Options, Vue } from "vue-class-component";
import { LockClosedIcon } from "@heroicons/vue/solid";
import { ForgotPassType, ForgotPassDefault } from "../types/user/forgot-pwd-type";
import { forgotPassHandler } from "../handlers/user/access-handlers";

@Options({
  components: {
    LockClosedIcon,
  },
})
export default class ForgotPassword extends Vue {
  private emailForm: ForgotPassType = ForgotPassDefault;
  private button = "Obnovit heslo!";

  async handleForgotPass(): Promise<void> {
    const payload = this.emailForm;
    this.button = "Načítám!";

    const response = await forgotPassHandler(payload);
    this.button = "Obnovit heslo!";
    if (response === undefined) return;
    await this.$router.push({ name: "Login" });
  }
}
